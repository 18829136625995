import React, { useEffect, useRef } from "react";

export default function Others(othObj) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const dropdownRef = useRef(null);

  const handleCompletionCheckboxClick = (event) => {
    othObj.handleFurnishClick(event);
    setDropdownOpen(false);
  };
  const handleFurnishedCheckboxClick = (event) => {
    othObj.handleFurnishClick(event);
    setDropdownOpen(false);
  };
  return (
    <>
      <div className="dropdown myinputdropdownmother">
        <button
          className="btn btn-light dropdown-toggle myinput myinputdropdown withoutdownicon"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          type="button"
          data-bs-display="static"
          aria-label="Filter"
          onClick={() => setDropdownOpen((p) => !p)}
        >
          &nbsp;
          <i className="la la-sliders" />
          &nbsp;
          <span>Filter</span>
        </button>
        <div
          className="dropdown-menu"
          style={{
            minWidth: "11rem",
          }}
          ref={dropdownRef}
        >
          <div className="mb-2">
            <label className="form-label mainlabel">Furnishing</label>
            <div className="radiolist monolabel">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="full"
                  onChange={handleFurnishedCheckboxClick}
                  checked={othObj.selectedFurnished.includes("full")}
                />
                <label className="form-check-label" htmlFor="full">
                  Furnished
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="partial"
                  onChange={handleFurnishedCheckboxClick}
                  checked={othObj.selectedFurnished.includes("partial")}
                />
                <label className="form-check-label" htmlFor="partial">
                  Partly Furnished
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="not"
                  onChange={handleFurnishedCheckboxClick}
                  checked={othObj.selectedFurnished.includes("not")}
                />
                <label className="form-check-label" htmlFor="not">
                  Unfurnished
                </label>
              </div>
            </div>
          </div>
          <div>
            <label className="form-label mainlabel">Completion</label>
            <div className="radiolist monolabel">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="off"
                  checked={othObj.selectedFurnished.includes("off")}
                  onChange={handleCompletionCheckboxClick}
                />
                <label className="form-check-label" htmlFor="off">
                  Off-plan
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="ready"
                  onChange={handleCompletionCheckboxClick}
                  checked={othObj.selectedFurnished.includes("ready")}
                />
                <label className="form-check-label" htmlFor="ready">
                  Ready
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
