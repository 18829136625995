/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from "next/router";
import AreaSqft from "../_inputs/AreaSqft";
import Bathrooms from "../_inputs/Bathrooms";
import Bedrooms from "../_inputs/Bedrooms";
import SearchEngineButtons from "../_inputs/Buttons";
import Locations from "../_inputs/Locations";
import Others from "../_inputs/Others";
import Price from "../_inputs/Price";
import Type from "../_inputs/Type";
import { useEffect, useRef, useState } from "react";
import { queryStringReducer } from "@/helpers/globalHelpers";

function convertToState(props) {
  return {
    businessType:
      props.businessType && props.businessType ? props.businessType : "sale",
    locations:
      props.locations && props.locations.length > 0
        ? props.locations.split(",")
        : [],
    types:
      props.propertyTypes !== null &&
      props.propertyTypes !== undefined &&
      props.propertyTypes !== ""
        ? Array.isArray(props.propertyTypes)
          ? props.propertyTypes
          : props.propertyTypes.split(",")
        : [],
    price: {
      min: props.priceMin ? props.priceMin : "0",
      max: props.priceMax ? props.priceMax : "Any",
    },
    sqft: {
      min: props.sizeMin ? props.sizeMin : "0",
      max: props.sizeMax ? props.sizeMax : "Any",
    },
    bedrooms: props.bedrooms ? props.bedrooms.split(",") : [],
    bathrooms: props.bathrooms ? props.bathrooms.split(",") : [],
    furnished: props.furnishes ? props.furnishes.split(",") : [],
    completion: props.completionStatus ? props.completionStatus.split(",") : [],
  };
}

export default function SearchModal(props) {
  const router = useRouter();
  const query = router.query;
  const searchButtonRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const defaultStates = {
    businessType: "sale",
    locations: [],
    types: [],
    price: {
      min: "0",
      max: "Any",
    },
    sqft: {
      min: "0",
      max: "Any",
    },
    bedrooms: [],
    bathrooms: [],
    furnished: [],
    completion: [],
    changed: false,
  };

  const [states, setStates] = useState(defaultStates);

  const data = {
    pageData: {
      businessTypeData: {
        selectedBusinessType: states.businessType,
        handleRentButton: () => {
          setStates((pQueries) => {
            const result = {
              ...pQueries,
              businessType: "rent",
              changed: true,
            };
            if (router.asPath.includes("luxury")) {
              result.price = {
                min: 500000,
                max: props.priceMax ? props.priceMax : "Any",
              };
            }
            return result;
          });
        },
        handleSaleButton: () => {
          setStates((pQueries) => {
            const result = {
              ...pQueries,
              businessType: "sale",
              changed: true,
            };
            if (router.asPath.includes("luxury")) {
              result.price = {
                min: 10000000,
                max: props.priceMax ? props.priceMax : "Any",
              };
            }
            return result;
          });
        },
      },
      locationData: {
        selectedLocations: states.locations,
        addLocation: (e) => {
          setStates((pQueries) => {
            // if it is not already in the array
            if (!pQueries.locations.includes(e.target.id)) {
              const result = {
                ...pQueries,
                changed: true,
                locations: [...pQueries.locations, e.target.id],
              };
              return result;
            } else {
              return pQueries;
            }
          });
        },
        removeLocation: (e) => {
          setStates((pQueries) => {
            const result = {
              ...pQueries,
              changed: true,
              locations: pQueries.locations.filter(
                (location, i) => i != e.target.id
              ),
            };
            return result;
          });
        },
      },
      typeData: {
        selectedTypes: states.types,
        handleTypeClick: (e) => {
          setStates((pQueries) => {
            if (!e.target.checked) {
              let arr = pQueries.types;
              arr.splice(arr.indexOf(e.target.name), 1);
              return {
                ...pQueries,
                types: [...arr],
                changed: true,
              };
            } else {
              return {
                ...pQueries,
                types: [...pQueries.types, e.target.name],
                changed: true,
              };
            }
          });

          document.querySelectorAll(`[id=${e.target.id}]`).forEach((c) => {
            c.checked = e.target.checked;
          });
        },
      },
      priceData: {
        selectedPrice: states.price,
        handlePriceMin: (e) => {
          setStates((pQueries) => {
            const result = {
              ...pQueries,
              changed: true,
              price: {
                min: e.target.id,
                max: pQueries.price.max,
              },
            };
            return result;
          });
        },
        handlePriceMax: (e) => {
          setStates((pQueries) => {
            const result = {
              ...pQueries,
              changed: true,
              price: {
                min: pQueries.price.min,
                max: e.target.id,
              },
            };
            return result;
          });
        },
        handleMinManual: (e) => {
          setStates((pQueries) => {
            let range = {
              min: e.target.value === "" ? "Any" : e.target.value,
              max: pQueries.sqft.max,
            };
            const result = {
              ...pQueries,
              changed: true,
              price: range,
            };
            return result;
          });
        },
        handleMaxManual: (e) => {
          setStates((pQueries) => {
            const result = {
              ...pQueries,
              changed: true,
              price: {
                min: pQueries.price.min,
                max: e.target.value === "" ? "Any" : e.target.value,
              },
            };
            return result;
          });
        },
      },
      sqftData: {
        selectedSqft: states.sqft,
        handleSqftMin: (e) => {
          setStates((pQueries) => {
            const range = {
              min: e.target.id,
              max: pQueries.sqft.max,
            };
            const result = {
              ...pQueries,
              changed: true,
              sqft: range,
            };
            return result;
          });
        },
        handleSqftMax: (e) => {
          setStates((pQueries) => {
            let range = { min: states.sqft.min, max: e.target.id };
            const result = {
              ...pQueries,
              changed: true,
              sqft: range,
            };
            return result;
          });
        },
        handleMinManual: (e) => {
          setStates((pQueries) => {
            let range = {
              min: e.target.value === "" ? "Any" : e.target.value,
              max: states.sqft.max,
            };
            const result = {
              ...pQueries,
              changed: true,
              sqft: range,
            };
            return result;
          });
        },
        handleMaxManual: (e) => {
          setStates((pQueries) => {
            let range = {
              min: pQueries.sqft.min,
              max: e.target.value === "" ? "Any" : e.target.value,
            };
            const result = {
              ...pQueries,
              changed: true,
              sqft: range,
            };
            return result;
          });
        },
      },
      bedroomsData: {
        selectedBedrooms: states.bedrooms,
        handleCheck: (e) => {
          if (!e.target.checked) {
            setStates((pQueries) => {
              let arr = pQueries.bedrooms;
              arr.splice(pQueries.bedrooms.indexOf(e.target.value), 1);
              const result = {
                ...pQueries,
                changed: true,

                bedrooms: [...arr],
              };
              return result;
            });
          } else {
            setStates((pQueries) => {
              const result = {
                ...pQueries,
                changed: true,

                bedrooms: [...pQueries.bedrooms, e.target.value],
              };
              return result;
            });
          }
          document.querySelectorAll(`[id=${e.target.id}]`).forEach((c) => {
            c.checked = e.target.checked;
          });
        },
      },
      bathroomsData: {
        selectedBathrooms: states.bathrooms,
        handleCheck: (e) => {
          if (!e.target.checked) {
            setStates((pQueries) => {
              let arr = pQueries.bathrooms;
              arr.splice(pQueries.bathrooms.indexOf(e.target.value), 1);
              const result = {
                ...pQueries,
                changed: true,

                bathrooms: [...arr],
              };
              return result;
            });
          } else {
            setStates((pQueries) => {
              const result = {
                ...pQueries,
                changed: true,

                bathrooms: [...pQueries.bathrooms, e.target.value],
              };
              return result;
            });
          }
          document.querySelectorAll(`[id=bdr-${e.target.id}]`).forEach((c) => {
            c.checked = e.target.checked;
          });
        },
      },
      othersData: {
        selectedFurnished: states.furnished,
        selectedCompletion: states.completion,
        handleFurnishClick: (e) => {
          if (!e.target.checked) {
            setStates((pQueries) => {
              let arr = pQueries.furnished;
              arr.splice(pQueries.furnished.indexOf(e.target.id), 1);
              const result = {
                ...pQueries,
                changed: true,

                furnished: [...arr],
              };
              return result;
            });
          } else {
            setStates((pQueries) => {
              const result = {
                ...pQueries,
                changed: true,

                furnished: [...pQueries.furnished, e.target.id],
              };
              return result;
            });
          }
        },
        handleCompletionClick: (e) => {
          if (!e.target.checked) {
            setStates((pQueries) => {
              let arr = pQueries.completion;
              arr.splice(pQueries.completion.indexOf(e.target.id), 1);
              const result = {
                ...pQueries,
                changed: true,

                completion: [...arr],
              };
              return result;
            });
          } else {
            setStates((pQueries) => {
              const result = {
                ...pQueries,
                changed: true,

                completion: [...pQueries.completion, e.target.id],
              };
              return result;
            });
          }
        },
      },
    },
    reset: () => {
      setStates((p) => ({
        businessType: p.businessType,
        locations: [],
        types: [],
        price: {
          min: "0",
          max: "Any",
        },
        sqft: {
          min: "0",
          max: "Any",
        },
        bedrooms: [],
        bathrooms: [],
        furnished: [],
        completion: [],
        changed: true,
      }));

      // go to the current route without query params
      // router.push(`/${query.lang}/${query.businessType}/${query.city}`);

      // uncheck all checkboxes
      document.querySelectorAll("input[type=checkbox]").forEach((c) => {
        c.checked = false;
      });

      hideModal();
    },
  };

  let pageData = data.pageData;

  const handleChange = () => {
    const q = {};

    q.businessType = states.businessType;

    let newPathName =
      "/en/" + q.businessType + "/properties-for-" + q.businessType;

    if (states.locations) {
      q.locations = states.locations.toString();
    }

    if (
      states.types !== [] &&
      states.types !== null &&
      states.types !== undefined
    ) {
      q.propertyTypes = states.types.toString();
    }

    if (states.price.min !== null) {
      q.priceMin = states.price.min;
    }

    if (states.price.max !== null) {
      q.priceMax = states.price.max;
    }

    if (states.sqft.min !== null) {
      q.sizeMin = states.sqft.min;
    }

    if (states.sqft.max !== null) {
      q.sizeMax = states.sqft.max;
    }

    if (states.bedrooms !== [] && states.bedrooms !== null) {
      q.bedrooms = states.bedrooms.toString();
    }

    if (states.bathrooms !== [] && states.bathrooms !== null) {
      q.bathrooms = states.bathrooms.toString();
    }

    if (states.furnished !== [] && states.furnished !== null) {
      q.furnishes = states.furnished.toString();
    }

    if (states.completion !== [] && states.completion !== null) {
      q.completionStatus = states.completion.toString();
    }

    const reducedQuery = queryStringReducer(q);
    hideModal();

    router.push({
      pathname: newPathName,
      query: reducedQuery,
    });
  };

  useEffect(() => {
    if (states.changed) {
      setIsLoading(true);
      handleChange();
      setStates((pQueries) => {
        const result = {
          ...pQueries,
          changed: false,
        };
        return result;
      });
      hideModal();
    }
  }, [states]);

  useEffect(() => {
    if (pageData !== undefined) {
      setIsLoading(false);
    }
  }, [pageData]);

  useEffect(() => {
    if (query.businessType !== undefined) {
      setStates((pQueries) => {
        const result = {
          ...pQueries,
          businessType: query.businessType,
        };
        return result;
      });
      // hideModal();
    }
  }, [query.businessType]);

  useEffect(() => {
    setStates(convertToState(props));
    // hideModal();
  }, [props]);

  const hideModal = () => {
    searchButtonRef.current.click();
  };

  return (
    <>
      <form className="rentbuyform">
        <div
          className="modal fade searchmodal"
          role="dialog"
          tabIndex={-1}
          id="searchmodal"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                <div className="row row-cols-2 row-cols-lg-3 w-100 m-0">
                  <div className="col-10 col-lg-4 order-first titlecol">
                    <div>
                      <p className="modal-title gilroybold h5">
                        Search your dream home
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 order-last order-lg-2 myslidercol">
                    <div className="rentorbuyslider">
                      <div className="rentorbuyform">
                        <div className="rentorbuymodalslider">
                          <SearchEngineButtons
                            {...pageData?.businessTypeData}
                            modal={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 col-lg-4 order-lg-last closecol">
                    <button
                      className="btn-close"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="close"
                      id="searchModalButton"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-body p-0">
                <div className="filterdiv">
                  <div className="filterinnerdiv">
                    <div className="row searchbarrow">
                      <div className="col-12 col-md-7 col">
                        <div className="myinputdiv">
                          <label className="form-label">Locations</label>
                          {!isLoading ? (
                            <Locations {...pageData?.locationData} />
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-5 col">
                        <div className="myinputdiv">
                          <label className="form-label">Type</label>
                          <span className={"reset-button"} onClick={data.reset}>
                            Reset all
                          </span>
                          {!isLoading ? <Type {...pageData?.typeData} /> : null}
                        </div>
                      </div>
                    </div>
                    <div className="row searchbarrow">
                      <div className="col-12 col-md-7 col">
                        <div className="myinputdiv">
                          <div className="yataydahep">
                            <div className="dropmothermother">
                              <label className="form-label">Price</label>
                              {!isLoading ? (
                                <Price {...pageData?.priceData} />
                              ) : null}
                            </div>
                            <div className="dropmothermother">
                              <label className="form-label">Area Sqft</label>
                              {!isLoading ? (
                                <AreaSqft {...pageData?.sqftData} />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col">
                        <div className="myinputdiv">
                          <div className="yataydahep">
                            <div className="dropmothermother">
                              <label className="form-label">Bedrooms</label>
                              <Bedrooms {...pageData?.bedroomsData} />
                            </div>
                            <div className="dropmothermother">
                              <label className="form-label">Bathrooms</label>
                              <Bathrooms {...pageData?.bathroomsData} />
                            </div>
                            <div className="dropmothermother">
                              <label className="form-label">Others</label>
                              <Others {...pageData?.othersData} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <a
        className="floatsearchbutton"
        href="#"
        data-bs-target="#searchmodal"
        data-bs-toggle="modal"
        ref={searchButtonRef}
        aria-label="search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="currentColor"
          viewBox="0 0 16 16"
          className="bi bi-search"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
        <span className="gilroybold d-none d-lg-inline">Search</span>
      </a>
    </>
  );
}
