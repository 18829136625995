import React from "react";

function SearchEngineButtons(props) {
  const ps = props.modal
      ? {
        "data-bs-target": "#searchmodal",
        "data-bs-toggle": "modal",
      }
      : {};
  return (
      <>
        <div className="rentorbuyslider2">
          <div className="rentorbuyform2">
            <div className="form-check" {...ps}>
              <input
                  className="form-check-input"
                  type="radio"
                  id="formCheck-2"
                  name="rentbuy"
                  checked={props.selectedBusinessType === "sale"}
                  onChange={() => props.handleSaleButton()}  // Utilisation de onChange au lieu de onClick
              />

              <label className="form-check-label" htmlFor="formCheck-2">
                Buy
              </label>
            </div>
            <div className="form-check" {...ps}>
              <input
                  className="form-check-input"
                  type="radio"
                  id="formCheck-1"
                  name="rentbuy"
                  checked={props.selectedBusinessType === "rent"}
                  onChange={() => props.handleRentButton()}  // Utilisation de onChange au lieu de onClick
              />

              <label className="form-check-label" htmlFor="formCheck-1">
                Rent
              </label>
            </div>
          </div>
        </div>
      </>
  );
}

export default SearchEngineButtons;
