import React, { useEffect, useRef } from "react";
import { bathRooms } from "../../../dictionaries/types";

export default function Bathrooms(bthObj) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const dropdownRef = useRef(null);

  const renderHeader = () => {
    let header;
    if (bthObj.selectedBathrooms.length > 0) {
      let headerLong = bthObj.selectedBathrooms.toString();
      header =
        headerLong.length > 15
          ? headerLong.substring(0, 13) + "..."
          : headerLong;
    } else {
      header = "Any";
    }
    return header;
  };

  const handleCheckboxClick = (event) => {
    bthObj.handleCheck(event);
    setDropdownOpen(false);
  };

  return (
    <>
      <div className="dropdown myinputdropdownmother">
        <button
          className="btn btn-light dropdown-toggle myinput myinputdropdown"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          type="button"
          data-bs-display="static"
          aria-label="Any"
          onClick={() => setDropdownOpen((p) => !p)}
        >
          {renderHeader()}
        </button>
        <div className="dropdown-menu" ref={dropdownRef}>
          <div>
            <label className="form-label mainlabel">Bedrooms</label>
            <div className="radiolist monolabel">
              {bathRooms.map((b, index) => {
                let checked = bthObj.selectedBathrooms.includes(b)
                  ? true
                  : false;
                return (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={"bth-" + b}
                      name="bedroom"
                      value={b}
                      defaultChecked={checked}
                      onClick={handleCheckboxClick}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"bth-" + b.replace(/\+/g, "")}
                    >
                      {b}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
